<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false"
    ></loading>

    <ModalType 
      :showType="showType" 
      :types="serviceTypes"/>

    <ModalConfirmation
      :show="showConfirmation"
      :leadStatus="leadStatus"/>

    <section>
      <b-row>
        <b-col lg="8" offset-lg="2" style="text-align: right">
          <b-button class="mr-1" style="margin-left:0.3rem;" @click="cancel">
            <strong>Ir a inicio</strong> &nbsp
            <CIcon name="cil-home" class="text-info"/> 
          </b-button>
        </b-col>
      </b-row>
      <br />
      <!-- Pesonal Data -->
      <b-row id="personalData">
        <b-col lg="8" offset-lg="2">
          <PersonalData 
            id="personalData"
          />
        </b-col>
      </b-row>

      <!-- Quotation -->
      <section id="quotation">
        <b-row v-if="lead != null">
          <b-col lg="8" offset-lg="2">
            <Quotation             
              :plans="plansFilteredByServiceName"/>
          </b-col>
        </b-row>
      </section>

      <!-- Resumen -->
      <section id="resumen">
        <b-row>
          <b-col lg="8" offset-lg="2">
          
            <Resumen 
              v-if="resumen != null"
              :resumen="resumen"/>
          </b-col>
        </b-row>
      </section>
    </section>
    <!-- Confirm -->
    <section id="confirm" v-if="resumen != null">
      <b-row style="margin-bottom: 2rem">
        <b-col lg="8" offset-lg="2">
          <h5>¿Deseas agendar el primer servicio?</h5>
          <p></p>
        </b-col>
        <b-col lg="6"  style="width:50%; text-align:right">
          <b-button 
            size="md" 
            variant="danger" 
            style="width:60%;"
            @click="registerLead(4)"
          >
          <!-- 4 no interesado -->
            No, gracias.
          </b-button>          
        </b-col>
        <b-col lg="6" style="width:50%; text-align:left">
          <b-button 
            size="md" 
            variant="success" 
            style="width:60%;"
            @click="registerLead(3)"
          >
          <!-- 3 Interesado -->
            Si, agendar
          </b-button>          
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import PersonalData from "./PersonalData";
import Quotation from "./Quotation";
import Resumen from "./Resumen";
import ModalType from "./ModalType";
import ModalConfirmation from "./ModalConfirmation";
import moment from 'moment'
export default {
  name: "serviceQuotationMain",
  components: { PersonalData, Loading, Quotation, Resumen, ModalType, ModalConfirmation },
  data() {
    return {
      loading: true,
      serviceId: null,
      lead: null,
      showType: false,
      showConfirmation: false,
      plans:[],
      types: null,
      typeSelected: null,
      quotation: null,
      leadStatus: null,
    };
  },
  created() {
    if ("serviceId" in this.$route.params) {
      let params = this.$route.params;
      this.serviceId = params.serviceId;
      // console.log("serviceId-main: ", this.serviceId);
    }

    this.fetchCities();
    this.fetchPlans();
    this.watchs();
  },
  computed: {
    serviceName(){
      if(this.serviceId){
        switch (this.serviceId) {
          case 1:
            return 'Paseo'
            break;
          case 2:
            return 'Adiestramiento'
            break;
        
          default:
            return null
            break;
        }
      }else{
        return null
      }
    },
    plansFilteredByServiceName(){
      if(this.serviceName != null && this.plans.length > 0){
        return this.plans.filter(
          plan => plan.servicio == this.serviceName
        )
      }else{
        return null
      }
    },
    serviceTypes(){
      if(this.plansFilteredByServiceName != null){
        let types = new Set()
        this.plansFilteredByServiceName.map(item => {
          types.add(item.tipo)
        })
        return types
      } else {
        return null
      }
    },    
    planSelected(){
      if (this.quotation != null && this.typeSelected != null) {      
        let planFiltered = this.plansFilteredByServiceName.filter(
          service =>
            service.tipo == this.typeSelected.type &&
            +service.horas_min <= +this.quotation.numServices &&
            +service.horas_max >= +this.quotation.numServices &&
            +service.mascotas == this.quotation.numPetsSelected.value
        )
        const height = document.querySelector('#quotation').offsetHeight
        setTimeout(function(){
            window.scrollTo(0,height+200)
        }, 100);
        this.$gtag.event('quoteService', { 
          'event_category': 'Step 2/2',        
        }) 
        return planFiltered[0]
      } else {
        return null
      }
    },
    resumen(){
      if(this.planSelected != null){
        let unitCost = null
        
        // if(
        //   this.quotation.numPetsSelected.value == 1 && 
        //   this.typeSelected.type == 'Grupal'){
        //     unitCost = +this.planSelected.precio + 1400
        // }else {
          unitCost = +this.planSelected.precio
        // }

        let item = {
          unitCost,
          numPets: this.quotation.numPetsSelected.value,          
          numServices: this.quotation.numServices, 
          serviceName: this.serviceName + ' '+ this.typeSelected.type         
        }
        item.total = item.unitCost * item.numServices
        this.$gtag.event('quoteService', { 
          'event_category': 'Show resumen',          
        }) 
        return item

      }else{
        return null
      }
    }
  },
  methods: {
    fetchCities() {
      this.$store.dispatch("fetchCities").then(cities => {
        this.cities = cities;
        this.loading = false;
      }).catch(e => {
        this.loading = false;
      });
    },
    fetchPlans(){
      this.$store.dispatch("service/fetchPlans").then(plans => {
        // console.log('plans: ', plans)
      }).catch(error => console.log(error))
    },
    cancel() {
      this.$gtag.event('quoteService', { 
        'event_category': 'Go to main',          
      }) 
      this.$router.push("/pages/main?pos=2");
    },
    watchs() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === "service/defLead") {
          this.lead = this.$store.getters["service/lead"];   
          if(this.lead != null){
            this.$gtag.event('quoteService', { 
              'event_category': 'Step 1/2',        
            }) 
            const height = document.querySelector('#personalData').offsetHeight
            setTimeout(function(){
                window.scrollTo(0,height)
            }, 100);
          }                                   
          
        }
        if (mutation.type === "service/defShowType") {
          this.showType = this.$store.getters["service/showType"];
        }
        if (mutation.type === "service/defShowConfirmation") {
          this.showConfirmation = this.$store.getters["service/showConfirmation"];
        }
        if (mutation.type === "service/defPlans") {
          this.plans = this.$store.getters["service/plans"];
        }
        if (mutation.type === "service/defTypeSelected") {
          this.typeSelected = this.$store.getters["service/typeSelected"];
        }
        if (mutation.type === "service/defQuotation") {
          this.quotation = this.$store.getters["service/quotation"];
        }
      });
    },
    registerLead(leadStatus){
      this.$gtag.event('quoteService', { 
        'event_category': 'Wants to buy',
        'event_label': leadStatus == 3 ? 'Yes' : 'No'        
      }) 
      this.leadStatus = leadStatus
      let phoneSplitted = this.lead.phone_number.split(' ')
      let phone = phoneSplitted[1]+phoneSplitted[2]
      let date = new Date()

      let payload = {
        nombre: this.lead.fullName,
        celular: phone,
        producto: [this.serviceName],
        fecha_contacto: moment(date).format('YYYY-MM-DDThh:mm:ss')+'.000Z',
        ciudades: {
          id: this.lead.city.value
        },
        comentarios: 'Interesado en ' +
          + this.quotation.numServices
          + ' '
          + this.serviceName 
          + ' '
          + this.typeSelected.type,
        procedencia: ['Web'],
        estadoprospecto: {
          id: leadStatus
        }
          
      }
      this.loading = true
      let createLead = this.$firebase.functions().httpsCallable('createLead')
      createLead(payload).then(res => {
        let data = res.data.data
        // console.log('data: ', data)
        if('code' in data){
          if(data.code == '1.1-2.1'){
            this.leadStatus=0
            this.showConfirmation = true     
          }
        }       
        this.showConfirmation = true        
        
      }).catch((error) => {
        console.log('Err: ', error)
      }).finally(_ => this.loading = false)
    }

  }
};
</script>

<style lang="scss" scoped></style>
