<template>
  <div>
    <b-modal
      :title="title"
      v-model="show"
      no-close-on-backdrop
      hide-header-close
      size="lg"
    >
      <div v-for="(msg, i) in msgs" v-bind:key="i">
        {{ msg }}
      </div>
      <br />
      También puedes escribirnos a nuestro wp de atención.
      <b-row>
        <b-col lg="6">
          <CButton
            class
            name="Whatsapp"
            size="sm"
            color="xing"
            target="_blank"
            @click.prevent="whatsapp('help')"
          >
            <CIcon size="lg" :name="'cib-Whatsapp'" />Whatsapp
          </CButton>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100">
          <b-row>
            <b-col lg="12" sm="12" style="text-align: center;">
              <b-button
                size="md"
                variant="success"
                @click="accept"
              >
                Aceptar
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "modalConfirmation",
  props: ["show", "leadStatus"],
  data() {
    return {
      wp: "https://api.whatsapp.com/send?phone="
    };
  },
  computed: {
    title() {
      if (this.leadStatus != null) {
        switch (this.leadStatus) {
          case 0:
            return "¡Tu solicitud ya ha sido registrada!";
            break;
          case 3:
            return "¡Gracias por confiar en nosotros!";
            break;
          case 4:
            return "Ha sido un placer atenderte";
            break;

          default:
            return "Gracias por contactarnos.";
            break;
        }
      } else {
        return "xx";
      }
    },
    msgs() {
      if (this.leadStatus != null) {
        switch (this.leadStatus) {
          case 0: //petition already registered
            return [
              "Pronto un asesor comercial te contactará para atender tu caso.",
              "¡Gracias!"
            ];
            break;
          case 3: //Interesado
            return [
              "Solicitud recibida exitosamente.",
              "Pronto un asesor comercial te contactará para atender tu caso.",
              "¡Gracias!"
            ];
            break;
          case 4:
            return [
              "Gracias por consultar nuestros servicios.",
              "Estamos para servirte."
            ];
            break;

          default:
            return "Gracias por contactarnos.";
            break;
        }
      } else {
        return [""];
      }
    }
  },
  methods: {
    whatsapp(subject) {
      let message = "";
      if (subject == "help") {
        this.wp = this.wp + "573194115792";
        message =
          this.wp +
          "&text=Hola, ya hice la cotización y estoy intersado en más información.";
      }
      window.open(message, "_blank");
    },
    accept(){
      this.$store.commit('service/defShowConfirmation', false);
    }
  }
};
</script>

<style lang="scss" scoped></style>
