var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{attrs:{"accent-color":"info"}},[_c('CButton',{staticClass:"text-left shadow-none card-header",attrs:{"block":"","color":"link"},on:{"click":function($event){_vm.accordion = _vm.accordion === 0 ? false : 0}}},[_c('b-row',[_c('b-col',{staticStyle:{"width":"70%"},attrs:{"lg":"6"}},[_c('span',[_c('h5',{staticClass:"m-0"},[_vm._v("Paso 2/2 "),(!this.$v.$invalid)?_c('CIcon',{staticClass:"text-success",attrs:{"name":"cil-check"}}):_vm._e()],1)])]),_c('b-col',{staticStyle:{"width":"30%","text-align":"right"},attrs:{"lg":"6"}},[_c('CIcon',{staticClass:"text-dark",attrs:{"name":_vm.accordion === false ? 'cil-chevron-bottom' : 'cil-chevron-top'}})],1)],1)],1),_c('CCollapse',{attrs:{"show":_vm.accordion === 0}},[_c('CCardBody',[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('strong',[_vm._v("Cantidad de mascotas *")]),_c('v-select',{class:{
                  'v-select is-invalid':_vm.$v.quotation.numPetsSelected.$error,
                  'v-select is-valid': !_vm.$v.quotation.numPetsSelected.$invalid
                },attrs:{"options":_vm.numPets,"placeholder":"Selecciona"},on:{"blur":_vm.numPetsBlur},model:{value:(_vm.quotation.numPetsSelected),callback:function ($$v) {_vm.$set(_vm.quotation, "numPetsSelected", $$v)},expression:"quotation.numPetsSelected"}}),(!_vm.$v.quotation.numPetsSelected.required)?_c('b-form-invalid-feedback',[_vm._v(" Debes seleccionar la cantidad de mascotas. ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('strong',[_vm._v("Cantidad de servicios *")]),_c('b-form-input',{class:{
                  'form-control is-invalid': _vm.$v.quotation.numServices.$error,
                  'form-control is-valid': !_vm.$v.quotation.numServices.$invalid
                },attrs:{"type":"number","id":"quantity","min":"1","max":"40","placeholder":""},on:{"blur":_vm.numServicesBlur},model:{value:(_vm.quotation.numServices),callback:function ($$v) {_vm.$set(_vm.quotation, "numServices", $$v)},expression:"quotation.numServices"}}),(!_vm.$v.quotation.numServices.required)?_c('b-form-invalid-feedback',[_vm._v(" Debes indicar la cantidad de servicios. ")]):_vm._e(),(!_vm.$v.quotation.numServices.minValue)?_c('b-form-invalid-feedback',[_vm._v(" La cantidad mínimas es "+_vm._s(_vm.$v.quotation.numServices.$params.minValue.min)+" ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('strong',[_vm._v(" Tipo de servicio * ")]),_c('b-form-group',[_c('b-button',{class:{
                  'v-select is-invalid':_vm.$v.typeSelected.$error,
                  'v-select is-valid': !_vm.$v.typeSelected.$invalid
                },staticStyle:{"width":"50%"},on:{"click":function($event){return _vm.showType()}}},[_vm._v(" "+_vm._s(_vm.typeSelected == null ? 'Selecciona' : _vm.typeSelected.type)+" ")]),(!_vm.$v.typeSelected.required)?_c('b-form-invalid-feedback',[_vm._v(" Debes seleccionar el tipo de paseo. ")]):_vm._e()],1)],1)],1)],1)],1)],1),_vm._v(" "+_vm._s(_vm.quotationPayload)+" ")],1)}
var staticRenderFns = []

export { render, staticRenderFns }