<template>
  <div>
    
    <b-modal
      title="Tipo de servicio"
      hide-header
      v-model="showType"
      no-close-on-backdrop
      hide-header-close
      size="lg"
    >
      <b-row>
        <b-col lg="12">
          <strong>Tipo de servicio*</strong>
          <p>Escoge una de las opciones</p>
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col lg="12">
          <div v-for="(type, i) in types" v-bind:key="i">
          <CCard
            :border-color="typeSelected == type ? 'success' : ''">
            <CCardBody 
              @click="onChange(type)">
              <b-form-group v-slot="{ ariaDescribedby }">           
                <b-form-radio                                    
                  v-model="typeSelected"
                  :aria-describedby="ariaDescribedby"
                  name="radios-stacked"
                  size="md"
                  :inline="true"
                  :value="type"
                  @change="onChange(type)"
                >
                  <h5>
                    {{type}}
                  </h5>                    
                  {{typeDetail(type)}}
                </b-form-radio>
              </b-form-group>
            </CCardBody>
          </CCard>
          </div>
          
        </b-col>
      </b-row>

      <template #modal-footer>
        <div class="w-100">
          <b-row>
            <b-col lg="12" sm="12" style="text-align: center;">
              <b-button 
                size="md" 
                variant="success" 
                @click="accept"
                :disabled="typeSelected != null ? false : true">
                Aceptar
              </b-button>
            </b-col>
            <!-- <b-col lg="6" sm="6" style="text-align: center; width: 50%">
              <b-button size="md" variant="secondary" @click="hideModal">
                Cancelar
              </b-button>
            </b-col> -->
          </b-row>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "quotationModal",
  props: ["showType", "types"],
  data() {
    return {
      typeSelected: null
    };
  },  
  methods: {
    accept() {
      this.$store.commit("service/defShowType", false);
    },
    hideModal() {
      this.$store.commit("service/defShowType", false);
    },
    onChange(type){
      // console.log('typeSelected: ', type)
      this.typeSelected = type
      this.$store.commit('service/defTypeSelected', {type})
    },
    typeDetail(type){
      if (type=='Grupal'){
        return 'Socializa con amigos. Grupos de máximo 4 mascotas'  
      } else if (type == 'Dedicado'){
        return 'Personalizado. Tu mascota será la única en el servicio'
      }

    }
  }
};
</script>

<style lang="scss" scoped>

</style>
