<template>
  <div>
    <CCard accent-color="success">
      <CCardHeader>
        <strong>Resumen</strong>
      </CCardHeader>
      <CCardBody>
        <b-row>
          <b-col lg="12" style="text-align: center"> <h4>{{resumen.serviceName}}</h4>  </b-col>

          <b-col style="min-width: 60%; text-align: right"> Costo und: </b-col>
          <b-col style="min-width: 40%; text-align: right"> <h5>{{resumen.unitCost | thousand}}</h5>     </b-col>     

          <b-col style="min-width: 60%; text-align: right"> Cantidad servicios:  </b-col>
          <b-col style="min-width: 40%; text-align: right"> <h5>{{resumen.numServices}}</h5>   </b-col>     
        </b-row>
        <hr />
        <b-row>
          <!-- <b-col style="min-width: 60%; text-align: right"> <strong>Subtotal: </strong> </b-col>
          <b-col style="min-width: 40%; text-align: right"> 94.800 </b-col>   -->

          <!-- <b-col style="min-width: 60%; text-align: right"> <strong>Descuentos: </strong> </b-col>
          <b-col style="min-width: 40%; text-align: right"> 4.800 </b-col>   -->
        </b-row>
        <br>
        <b-row>
          <b-col 
            lg="12" 
            style="text-align: right"> 
              <h4>
                TOTAL: 
                {{resumen.total | thousand}} 
              </h4> 
          </b-col>
        </b-row>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
export default {
  name: "quotationResumen",
  props: ['resumen'],
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped></style>
