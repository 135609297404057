var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{attrs:{"accent-color":"info"}},[_c('CButton',{staticClass:"text-left shadow-none card-header",attrs:{"block":"","color":"link"},on:{"click":function($event){_vm.accordion = _vm.accordion === 0 ? false : 0}}},[_c('b-row',[_c('b-col',{staticStyle:{"width":"70%"},attrs:{"lg":"6"}},[_c('span',[_c('h5',{staticClass:"m-0"},[_vm._v("Paso 1/2 "),(_vm.infoRegistered)?_c('CIcon',{staticClass:"text-success",attrs:{"name":"cil-check"}}):_vm._e()],1)])]),_c('b-col',{staticStyle:{"width":"30%","text-align":"right"},attrs:{"lg":"6"}},[_c('CIcon',{staticClass:"text-dark",attrs:{"name":_vm.accordion === false ? 'cil-chevron-bottom' : 'cil-chevron-top'}})],1)],1)],1),_c('CCollapse',{attrs:{"show":_vm.accordion === 0}},[_c('CCardBody',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},_vm._l((_vm.errorMsgs),function(msg,i){return _c('b-alert',{key:i,attrs:{"variant":"danger","dismissible":""},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}},[_vm._v(" "+_vm._s(msg)+" ")])}),1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"firstName"}},[_c('strong',[_vm._v(" Nombre y apellido * ")])]),_c('b-form-input',{class:{
                  'form-control is-invalid': _vm.$v.lead.fullName.$error,
                  'form-control is-valid': !_vm.$v.lead.fullName.$invalid
                },attrs:{"type":"text","id":"fullName","placeholder":"Inserta tus nombres"},model:{value:(_vm.$v.lead.fullName.$model),callback:function ($$v) {_vm.$set(_vm.$v.lead.fullName, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.lead.fullName.$model"}}),(!_vm.$v.lead.fullName.required)?_c('b-form-invalid-feedback',[_vm._v("Tu nombre es necesario")]):_vm._e(),(!_vm.$v.lead.fullName.minLength)?_c('b-form-invalid-feedback',[_vm._v("El primer nombre debe tener como mínimo "+_vm._s(_vm.$v.lead.fullName.$params.minLength.min)+" letras")]):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Phone"}},[_c('strong',[_vm._v(" Celular de contacto * ")])]),_c('vue-tel-input',_vm._b({class:{
                  'form-control is-invalid': _vm.$v.lead.phone_number.$error,
                  'form-control is-valid': !_vm.$v.lead.phone_number.$invalid
                },attrs:{"placeholder":"Tu número de teléfono","locale":"es-Es"},model:{value:(_vm.$v.lead.phone_number.$model),callback:function ($$v) {_vm.$set(_vm.$v.lead.phone_number, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.lead.phone_number.$model"}},'vue-tel-input',_vm.phoneNumberProps,false)),(!_vm.$v.lead.phone_number.minLength)?_c('b-form-invalid-feedback',[_vm._v(" El número de teléfono debe tener como mínimo "+_vm._s(_vm.$v.lead.phone_number.$params.minLength.min)+" números. ")]):_vm._e(),(!_vm.$v.lead.phone_number.phoneNumber)?_c('b-form-invalid-feedback',[_vm._v("Debe tener solo números.")]):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('strong',[_vm._v("Ciudad *")]),(_vm.cities != null)?_c('v-select',{class:{
                  'v-select is-invalid': _vm.$v.lead.city.$error,
                  'v-select is-valid': !_vm.$v.lead.city.$invalid
                },attrs:{"options":_vm.cities,"placeholder":"Selecciona"},model:{value:(_vm.lead.city),callback:function ($$v) {_vm.$set(_vm.lead, "city", $$v)},expression:"lead.city"}}):_vm._e(),(!_vm.$v.lead.city.required)?_c('b-form-invalid-feedback',[_vm._v(" Debes seleccionar una ciudad. ")]):_vm._e()],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }