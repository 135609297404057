<template>
  <div>
    <CCard accent-color="info">     
      <CButton
        block
        color="link"
        class="text-left shadow-none card-header"
        @click="accordion = accordion === 0 ? false : 0"
      >
        <b-row>
          <b-col lg="6" style="width: 70%"> 
            <span>
              <h5 class="m-0">Paso 1/2            
                <CIcon v-if="infoRegistered" name="cil-check" class="text-success"/> 
              </h5>
            </span>
          </b-col>
          <b-col lg="6" style="width: 30%; text-align:right">
            <CIcon  
              :name="accordion === false ? 'cil-chevron-bottom' : 'cil-chevron-top'" 
              class="text-dark"/> 
          </b-col>
        </b-row>
      </CButton>
      <CCollapse :show="accordion === 0">
        <CCardBody>
          <b-row>
            <b-col cols="12">
              <b-alert
                v-model="error"
                variant="danger"
                dismissible
                v-for="(msg, i) in errorMsgs"
                v-bind:key="i"
              >
                {{ msg }}
              </b-alert>
            </b-col>
          </b-row>

          <b-row>
            <!-- FullName -->
            <b-col sm="6">
              <b-form-group>
                <label for="firstName">
                  <strong>
                    Nombre y apellido *
                  </strong>
                </label>
                <b-form-input
                  type="text"
                  id="fullName"
                  v-model.trim="$v.lead.fullName.$model"
                  placeholder="Inserta tus nombres"
                  :class="{
                    'form-control is-invalid': $v.lead.fullName.$error,
                    'form-control is-valid': !$v.lead.fullName.$invalid
                  }"
                ></b-form-input>
                <b-form-invalid-feedback v-if="!$v.lead.fullName.required"
                  >Tu nombre es necesario</b-form-invalid-feedback
                >
                <b-form-invalid-feedback v-if="!$v.lead.fullName.minLength"
                  >El primer nombre debe tener como mínimo
                  {{ $v.lead.fullName.$params.minLength.min }}
                  letras</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>

            <!-- Phone number -->
            <b-col md="6">
              <b-form-group>
                <label for="Phone">
                  <strong>
                    Celular de contacto *
                  </strong>
                </label>
                <vue-tel-input
                  v-model.trim="$v.lead.phone_number.$model"
                  placeholder="Tu número de teléfono"
                  locale="es-Es"
                  :class="{
                    'form-control is-invalid': $v.lead.phone_number.$error,
                    'form-control is-valid': !$v.lead.phone_number.$invalid
                  }"
                  v-bind="phoneNumberProps"
                ></vue-tel-input>
                <b-form-invalid-feedback v-if="!$v.lead.phone_number.minLength">
                  El número de teléfono debe tener como mínimo
                  {{ $v.lead.phone_number.$params.minLength.min }}
                  números.
                </b-form-invalid-feedback>
                <b-form-invalid-feedback v-if="!$v.lead.phone_number.phoneNumber"
                  >Debe tener solo números.</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>

            <!-- Cities -->
            <b-col lg="6">
              <b-form-group>
                <strong>Ciudad *</strong>
                <v-select
                  v-if="cities != null"
                  :options="cities"
                  v-model="lead.city"
                  placeholder="Selecciona"
                  :class="{
                    'v-select is-invalid': $v.lead.city.$error,
                    'v-select is-valid': !$v.lead.city.$invalid
                  }"
                >
                  
                </v-select>
                <b-form-invalid-feedback v-if="!$v.lead.city.required">
                  Debes seleccionar una ciudad.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </CCardBody>
      </CCollapse>
    </CCard>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
const phoneNumber = number => {
  if (!!number) {
    return !!number.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g);
  } else {
    return false;
  }
};
export default {
  data() {
    return {
      errorMsgs: [],
      error: false,
      disableSave: false,
      accordion: 0,
      lead: {
        fullName: null,
        phone_number: null,
        city: { value: 1, label: "Popayán, Cauca, Colombia" }
      },
      phoneNumberProps: {
        mode: "international",
        defaultCountry: "CO",
        enabledCountryCode: true
      },
      // cities: null
      cities: [
        {value:1, label:'Popayán, Cauca, Colombia'}
      ]
    };
  },
  validations: {
    lead: {
      fullName: {
        required,
        minLength: minLength(2)
      },
      phone_number: {
        required,
        minLength: minLength(7),
        phoneNumber
      },
      city: {
        required
      }
    }
  },
  computed: {
    infoRegistered(){
      if(!this.$v.$invalid){
        // console.log('personal data registered')
        this.$store.commit('service/defLead', this.lead)
        return true
      }else{
        this.$store.commit('service/defLead', null)
        return false
      }
    },  
  },
  created() {
    this.watchs();    
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.error = true;
        this.errorMsgs = ["No has completado correctamente tus datos"];
        this.scrollToTop();
      } else {
        this.error = false;
        this.errorMsgs = [];
        
        this.$store.commit('service/defLead', this.lead)
      }
    },
    watchs() {
      this.$store.subscribe((mutation, state) => {      
        if (mutation.type === "defCities") {
          this.cities = this.$store.getters.cities;
          // console.log(this.cities)
        }
      });
    },
    cancel() {
      this.$router.push("/services/profile/" + serviceId);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    nameWithLang ({ value, label }) {
      return `${label}`
    },   
  }
};
</script>

<style scoped src="vue-tel-input/dist/vue-tel-input.css">
</style>

