<template>
  <div>
    <CCard accent-color="info">
      <CButton
        block
        color="link"
        class="text-left shadow-none card-header"
        @click="accordion = accordion === 0 ? false : 0"
      >
        <b-row>
          <b-col lg="6" style="width: 70%"> 
            <span>
              <h5 class="m-0">Paso 2/2            
                <CIcon v-if="!this.$v.$invalid" name="cil-check" class="text-success"/> 
              </h5>
            </span>
          </b-col>
          <b-col lg="6" style="width: 30%; text-align:right">
            <CIcon  
              :name="accordion === false ? 'cil-chevron-bottom' : 'cil-chevron-top'" 
              class="text-dark"/> 
          </b-col>
        </b-row>
      </CButton>
      <CCollapse :show="accordion === 0">
        <CCardBody>      
          <b-row>
            <!-- Cities -->
            <b-col lg="6">
              <b-form-group>
                <strong>Cantidad de mascotas *</strong>
                <v-select
                  :options="numPets"
                  v-model="quotation.numPetsSelected"
                  placeholder="Selecciona"
                  :class="{
                    'v-select is-invalid':$v.quotation.numPetsSelected.$error,
                    'v-select is-valid': !$v.quotation.numPetsSelected.$invalid
                  }"
                  v-on:blur="numPetsBlur"
                >
                </v-select>
                <b-form-invalid-feedback
                  v-if="!$v.quotation.numPetsSelected.required"
                >
                  Debes seleccionar la cantidad de mascotas.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>         

            <!-- Number of Services -->
            <b-col sm="6">
              <b-form-group>
                <strong>Cantidad de servicios *</strong>
                <b-form-input
                  type="number"
                  id="quantity"
                  min="1"
                  max="40"
                  placeholder=""
                  v-model="quotation.numServices"
                  v-on:blur="numServicesBlur"
                  :class="{
                    'form-control is-invalid': $v.quotation.numServices.$error,
                    'form-control is-valid': !$v.quotation.numServices.$invalid
                  }"
                >
                </b-form-input>
                <b-form-invalid-feedback
                  v-if="!$v.quotation.numServices.required"
                  >
                  Debes indicar la cantidad de servicios.
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  v-if="!$v.quotation.numServices.minValue"
                  >
                  La cantidad mínimas es {{$v.quotation.numServices.$params.minValue.min}}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Service Type -->
            <b-col lg="6">
              <strong>
                Tipo de servicio *
              </strong>
              <b-form-group>
                <b-button 
                  @click="showType()"
                  style="width: 50%"
                  :class="{
                    'v-select is-invalid':$v.typeSelected.$error,
                    'v-select is-valid': !$v.typeSelected.$invalid
                  }">
                  {{typeSelected == null ? 'Selecciona' : typeSelected.type}}
                </b-button>
                <b-form-invalid-feedback
                  v-if="!$v.typeSelected.required"
                  >
                  Debes seleccionar el tipo de paseo.
                </b-form-invalid-feedback>

              </b-form-group>
            </b-col>
          </b-row>
        </CCardBody>
      </CCollapse>
    </CCard>
    {{quotationPayload}}
  </div>
</template>

<script>
import { required, minLength, minValue } from "vuelidate/lib/validators";
export default {
  name: "serviceQuote",
  props: ['plans'],
  data() {
    return {
      accordion: 0,
      quotation: {
        numPetsSelected: {value: 1, label:"1"},
        numServices: 1
      },
      numPets: [
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" }
      ],
      typeSelected: null
      // plans: [],
    };
  },
  validations: {
    quotation: {
      numPetsSelected: {
        required
      },
      numServices: {
        required,
        minValue: minValue(1)
      }
    },
    typeSelected: {
      required
    }
  },
  created() {
    this.watchs();
  },
  computed: {
    quotationPayload(){
      if(!this.$v.$invalid){
        this.$store.commit('service/defQuotation', this.quotation)
        return ''
      } else {
        this.$v.$touch();
        this.$store.commit('service/defQuotation', null)
        return null
      }
    }
  },
  methods: {
    showType() {
      let showType = this.$store.getters["service/showType"];
      // console.log("showType: ", !showType);
      this.$store.commit("service/defShowType", !showType);
    },
    watchs() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === "service/defTypeSelected") {
          this.typeSelected = this.$store.getters["service/typeSelected"];
        }     
      });
    },
    numPetsBlur(){
      this.$v.$touch();
    },
    numServicesBlur(){
      //  if(!this.$v.$invalid){
      //   console.log('onChange-NumServices-if')
      //   this.$store.commit('service/defQuotation', this.quotation)
      // } else {
      //   console.log('onChange-NumServices-else')
      //   this.$store.commit('service/defQuotation', null)
      // }
    }
  }
};
</script>

<style lang="scss" scoped></style>
